// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-platform-modal .modal-content {
  min-width: 51rem;
}

.app-platform-modal .dropdown-plans-description {
  min-width: 12rem;
}

.app-platform-modal .search-container .input {
  display: block;
  width: 100%;
}

.app-platform-modal .calculation-container {
  width: 15rem;
}`, "",{"version":3,"sources":["webpack://./src/modals/AppPlatformModal.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".app-platform-modal .modal-content {\n  min-width: 51rem;\n}\n\n.app-platform-modal .dropdown-plans-description {\n  min-width: 12rem;\n}\n\n.app-platform-modal .search-container .input {\n  display: block;\n  width: 100%;\n}\n\n.app-platform-modal .calculation-container {\n  width: 15rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-modal .modal-radio-buttons {
    align-items: unset;
    width: 50%;
    min-width: -moz-fit-content;
    min-width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/modals/SupportModal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,2BAAsB;IAAtB,sBAAsB;AAC1B","sourcesContent":[".support-modal .modal-radio-buttons {\n    align-items: unset;\n    width: 50%;\n    min-width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

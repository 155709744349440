import React, { useState, useRef, useEffect } from 'react';
import './KubernetesModal.css';

function KubernetesModal({ closeModal, addQuoteItem, formatPrice, plansDroplets }) {
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [nodes, setNodes] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [quantityHighAvailability, setQuantityHighAvailability] = useState(1);
  const [gpuHours, setGpuHours] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const searchInputRef = useRef(null);
  const quantityInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState('Node Pools');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSelectedPlan(null);
    setShowDropdown(false);
    setNodes(1);
    setGpuHours(1);
  };

  const minGpuHours = 1;
  const maxGpuHours = 672;

  // Focus and select the quantity input when a plan is selected
  useEffect(() => {
    if (selectedPlan) {
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  }, [selectedPlan]);

  // Filter plans based on the search term by applying regex across various plan attributes
  const filteredPlans = plansDroplets.filter(plan => {
    if (!plan.Doks) {
      return false;
    } else if (plan.GPU) {
      return false;
    }
    
    // Create a regex from the search term, escaping special characters to prevent regex errors
    const regex = new RegExp(searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    
    // Test regex against plan fields, converting numeric fields to string with descriptive text
    return (
      regex.test(plan.Description) ||  // Check plan description
      regex.test(plan.Slug) ||  // Check plan slug or identifier
      regex.test(`${plan.VCPUs} vCPU`) ||  // Check virtual CPUs with 'vCPU' appended
      regex.test(`${plan.Memory} GiB Memory`) ||  // Check memory size with 'GiB Memory' appended
      regex.test(`${plan.Disk} GiB Disk`) ||  // Check disk size with 'GiB Disk' appended
      regex.test(plan.Nvme) ||  // Check if NVMe is supported
      regex.test(`${plan.Network} Gbps Network`) ||  // Check network speed with 'Gbps Network' appended
      regex.test(`${plan.Transfer} GiB Transfer`) ||  // Check data transfer limit with 'GiB Transfer' appended
      regex.test(`$${plan['Price Monthly']} Monthly`)  // Check monthly price with 'Monthly' appended
    );
  });

  // Filter GPU Droplet plans based on the search term by applying regex across various plan attributes
  const filteredGpuPlans = plansDroplets.filter(plan => {
    if (!plan.Doks) {
      return false;
    } else if (!plan.GPU) {
      return false;
    }

    // Create a regex from the search term, escaping special characters to prevent regex errors
    const regex = new RegExp(searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    
    // Test regex against plan fields, converting numeric fields to string with descriptive text
    return (
      regex.test(plan.Description) ||  // Check plan description
      regex.test(plan.Slug) ||  // Check plan slug or identifier
      regex.test(`${plan.VCPUs} vCPU`) ||  // Check virtual CPUs with 'vCPU' appended
      regex.test(`${plan.Memory} GB Memory`) ||  // Check memory size with 'GB Memory' appended
      regex.test(`${plan.Disk} GiB Disk`) ||  // Check disk size with 'GiB Disk' appended
      regex.test(plan.Nvme) ||  // Check if NVMe is supported
      regex.test(`${plan.Network} Gbps Network`) ||  // Check network speed with 'Gbps Network' appended
      regex.test(`$${plan['Price Hourly']} Hourly`)  // Check monthly price with 'Hourly' appended
    );
  });

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setShowDropdown(false);
    setSearchTerm(''); // Clear the search term after selection
    setQuantity(1); // Set default quantity to 1
  };

	// Function to increment Nodes by 1, capped at the maximum limit
	const handleNodesIncrement = () => {
    const maxNodes = 512;
    setNodes(prevNodes => {
        let newNodes = prevNodes < maxNodes ? prevNodes + 1 : prevNodes;
        calculateTotalNodePoolPrice(selectedPlan, newNodes);
        return newNodes;
    });
	};

	// Function to decrement Nodes by 1, capped at the minimum limit
	const handleNodesDecrement = () => {
    const minNodes = 1;
    setNodes(prevNodes => {
        let newNodes = prevNodes > minNodes ? prevNodes - 1 : prevNodes;
        calculateTotalNodePoolPrice(selectedPlan, newNodes);
        return newNodes;
    });
	};

  // Handle Nodes change
  const handleNodesChange = (e) => {
    const inputString = e.target.value;
    const value = parseInt(inputString, 10);

    if (inputString === "") {
        setNodes(""); // Allow the field to be empty while typing
    } else if (isNaN(value) || value < 1 || value > 512) {
        setNodes(1); // Default to 1 when out of range or invalid entry is completed
        calculateTotalNodePoolPrice(selectedPlan, 1); // Recalculate price on input completion
    } else {
        setNodes(value);
        calculateTotalNodePoolPrice(selectedPlan, value); // Recalculate price on valid input
    }
  };
	
	// Handle Nodes blur
	const handleNodesBlur = () => {
    let validNodes = Math.min(Math.max(1, nodes), 512);
    setNodes(validNodes); // Adjust to valid number of nodes on blur
    calculateTotalNodePoolPrice(selectedPlan, validNodes); // Recalculate price on blur
	};  

  // Handle quantity change for Node Pools
  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number(value) <= 10000)) {
      setQuantity(value);
    }
  };

  // Handle quantity blur (when input loses focus) for Node Pools
  const handleQuantityBlur = () => {
    if (quantity === '' || quantity < 1) {
      setQuantity(1);
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  };

  // Handle quantity change for High Availability
  const handleQuantityHighAvailabilityChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number(value) <= 10000)) {
      setQuantityHighAvailability(value);
    }
  };

  // Handle quantity blur (when input loses focus) for High Availability
  const handleQuantityHighAvailabilityBlur = () => {
    if (quantityHighAvailability === '' || quantityHighAvailability < 1) {
      setQuantityHighAvailability(1);
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  };

  // Calculate the total Node Pool price
  const calculateTotalNodePoolPrice = () => {
    if (!selectedPlan) return 0;
    let price = parseFloat(selectedPlan['Price Monthly']);
    return formatPrice(price * (nodes || 1) * (quantity || 1));
  };

  const totalNodePoolPrice = calculateTotalNodePoolPrice();

  // Calculate the total GPU Node Pool price
  const calculateTotalGpuNodePoolPrice = () => {
    if (!selectedPlan) return 0;
    let price = parseFloat(selectedPlan['Price Hourly']) * gpuHours;
    return formatPrice(price * (nodes || 1) * (quantity || 1));
  };

  const totalGpuNodePoolPrice = calculateTotalGpuNodePoolPrice();  

	// Function to increment the GPU hours by 1, capped at the maximum limit
	const handleGpuHoursIncrement = () => {
		setGpuHours(gpuHours+1);
		calculateTotalGpuNodePoolPrice();
	};

	// Function to decrement the GPU hours by 1, capped at the minimum limit
	const handleGpuHoursDecrement = () => {
		setGpuHours(gpuHours-1);
		calculateTotalGpuNodePoolPrice();
	};

  // Handle adding the selected Node Pool to the quote
  const handleAddNodePool = () => {
    if (selectedPlan) {
      let description = `${selectedPlan.Description} - ${selectedPlan.VCPUs} vCPU / ${selectedPlan.Memory} GiB Memory / ${selectedPlan.Disk} GiB Disk / ${selectedPlan.Transfer} GiB Egress.`;
      let features = '';    
      if (selectedPlan.Nvme === 'nvme') {
        features += 'NVMe Guaranteed.';
      }
      if (selectedPlan.Network === '10') {
        features += ' 10 Gbps Network.';
      }

    	// Append the number of nodes to the description
    	if (nodes > 1) {
      	description = `${nodes}x ` + description;
    	}	

      addQuoteItem({
        serviceType: 'Kubernetes Node Pools',
        name: name,
        description: description,
        features: features,
        transfer: selectedPlan.Transfer * 1/1024 * nodes,
        priceMonthly: formatPrice(calculateTotalNodePoolPrice().replace(/,/g, '') / quantity),
        quantity: quantity || 1,
        totalPrice: totalNodePoolPrice
      });
      closeModal(); // Close the modal after adding the quote item
    }
  };

  // Handle adding the selected GPU Node Pool plan to the quote
  const handleAddGpuNodePool = () => {
    let description = `${selectedPlan.Description} - ${selectedPlan.VCPUs} vCPU / ${selectedPlan.Memory} GiB Memory / ${selectedPlan.Disk} GiB Boot Disk / ${selectedPlan.Transfer} GiB Egress.`;
    let features = `GPU Node Pool with ${gpuHours} hour${(gpuHours % 10 === 1 && gpuHours % 100 !== 11) ? '' : 's'} of usage per month.`;

    // Append the number of nodes to the description
    if (nodes > 1) {
      description = `${nodes}x ` + description;
    }	

    addQuoteItem({
      serviceType: 'Kubernetes Node Pools',
      name: name,
      description: description,
      features: features,
      transfer: selectedPlan.Transfer * 1/1024 * nodes,
      priceMonthly: formatPrice(calculateTotalGpuNodePoolPrice().replace(/,/g, '') / quantity),
      quantity: quantity || 1,
      totalPrice: totalGpuNodePoolPrice
    });
    closeModal(); // Close the modal after adding the quote item
  };

  // Handle adding High Availability to the quote
  const handleAddHighAvailability = () => {
    addQuoteItem({
      serviceType: 'Kubernetes High Availability',
      name: name,
      description: 'Control Plane High Availability with 99.95% uptime SLA for a single Kubernetes cluster.',
      priceMonthly: 40,
      quantity: quantityHighAvailability,
      totalPrice: 40 * quantityHighAvailability
    });
    closeModal(); // Close the modal after adding the quote item
  };
  
  return (
    <div id="modal" className="modal support-modal">
      <div id="modal-content" className="modal-content">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="modal-header" className="modal-header flex flex-between flex-center">
          <div>
            <h1>Add Kubernetes</h1>
          </div>
          <div id="modal-item-name" className="modal-item-name">
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter item name or description"
              maxLength="50"
            />
          </div>
        </div>
        <div id="modal-tabs" className="modal-tabs">
          <button
            className={`tab-button ${activeTab === 'Node Pools' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Node Pools')}
          >
            Node Pools
          </button>
          <button
            className={`tab-button ${activeTab === 'GPU Node Pools' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('GPU Node Pools')}
          >
            GPU Node Pools
          </button>         
          <button
            className={`tab-button ${activeTab === 'High Availability' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('High Availability')}
          >
            High Availability
          </button>
        </div>
        <div id="modal-body" className="modal-body">
          {activeTab === 'Node Pools' && (
            <div>
              <h5>SELECT WORKER NODE PLAN</h5>
              <div id="search-container" className="search-container">
                <input
                  ref={searchInputRef}
                  type="text"
                  className="input"
                  placeholder="Search plans or click to view all"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setShowDropdown(true);
                  }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
                {showDropdown && filteredPlans.length > 0 && (
                  <ul id="dropdown" className="dropdown">
                    {filteredPlans.map((plan, index) => (
                      <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray">
                        <div className="dropdown-plans-description">
                          {plan.Description}
                        </div>
                        <div className="dropdown-plans-specifications">
                          {plan.VCPUs} vCPU, {plan.Memory} GiB Memory, {plan.Disk} GiB Disk
                        </div>
                        <div id="price" className="price">
                          <span className="dollar">$</span>
                          <span>{formatPrice(parseInt(plan['Price Monthly'], 10))}</span>
                          <span className="period">/mo</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {showDropdown && filteredPlans.length === 0 && (
                  <ul id="dropdown" className="dropdown">
                    <li>No plans found</li>
                  </ul>
                )}
              </div>
              {selectedPlan && (
                <div>
                  <div id="card" className="card">
                    <div id="card-header" className="card-header flex flex-between flex-center">
                      <div>
                        <h5>SELECTED PLAN</h5>
                      </div>
                      <div id="price" className="price">
                        <span className="dollar">$</span>
                        <span className="cost">{selectedPlan['Price Monthly']}</span>
                        <span className="period">/mo</span>
                      </div>
                    </div>
                    <div id="card-body" className="card-body">
                      <p>{selectedPlan.Description}</p>
                      <p>{selectedPlan.VCPUs} vCPU / {selectedPlan.Memory} GiB Memory / {selectedPlan.Disk} GiB Disk / {selectedPlan.Transfer} GiB Egress</p>
                    </div>
                  </div>

                  <h5>SELECT NUMBER OF NODES</h5>
                  <div className="scaler-container">
                      <div className="scaler-input-wrapper">
                          <div className="scaler-input">
                              <input 
                              type="number" 
                              className="scaler-input-field" 
                              name="storage-count" 
                              placeholder="" 
                              min="0"
                              max="512" 
                              aria-invalid="false" 
                              autoComplete="off" 
                              spellCheck="false" 
                              value={nodes}
                              onChange={handleNodesChange}
                              onBlur={handleNodesBlur}
                            />
                          </div>
                      </div>
                      <div className="scaler-button-wrapper-up">
                          <button 
                              className="scaler-button-increment" 
                              onClick={handleNodesIncrement}
                              disabled={nodes >= 512}
                          >
                              <svg className="scaler-icon-up" aria-hidden="true">
                                  <use href="#_sprite-plus"></use>
                              </svg>
                          </button>
                      </div>
                      <div className="scaler-button-wrapper-down">
                          <button 
                              className="scaler-button-decrement" 
                              onClick={handleNodesDecrement}
                              disabled={nodes <= 1}
                          >
                              <svg className="scaler-icon-down" aria-hidden="true">
                                  <use href="#_sprite-minus"></use>
                              </svg>
                          </button>
                      </div>
                    </div>
                  <h5>SELECT NODE POOL QUANTITY</h5>
                  <input
                    ref={quantityInputRef}
                    type="number"
                    className="input"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    min="1"
                    max="10000"
                  />
                </div>
              )}
            </div>
          )}
          {activeTab === 'GPU Node Pools' && (
            <div>
              <h5>SELECT GPU WORKER NODE PLAN</h5>
              <div id="search-container" className="search-container">
                <input
                  ref={searchInputRef}
                  type="text"
                  className="input"
                  placeholder="Search plans or click to view all"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setShowDropdown(true);
                  }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
                {showDropdown && filteredGpuPlans.length > 0 && (
                  <ul id="dropdown" className="dropdown">
                    {filteredGpuPlans.map((plan, index) => (
                      <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray">
                        <div className="dropdown-plans-description">
                          {plan.Description}
                        </div>
                        <div className="dropdown-plans-specifications">
                          {plan.VCPUs} vCPU, {plan.Memory} GiB Memory, {plan.Disk} GiB Boot Disk
                        </div>
                        <div id="price" className="price">
                          <span className="dollar">$</span>
                          <span>
                            {formatPrice(plan['Price Hourly'])}
                          </span>
                          <span className="period">/hr</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {showDropdown && filteredPlans.length === 0 && (
                  <ul id="dropdown" className="dropdown">
                    <li>No plans found</li>
                  </ul>
                )}
              </div>
              {selectedPlan && (
                <div>
                  <div id="card" className="card">
                    <div id="card-header" className="card-header flex flex-between flex-center">
                      <div>
                        <h5>SELECTED PLAN</h5>
                      </div>
                      <div id="price" className="price">
                        <span className="dollar">$</span>
                        <span className="cost">{selectedPlan['Price Hourly']}</span>
                        <span className="period">/hr</span>
                      </div>
                    </div>
                    <div id="card-body" className="card-body">
                      <p>{selectedPlan.Description}</p>
                      <p>{selectedPlan.VCPUs} vCPU / {selectedPlan.Memory} GiB Memory / {selectedPlan.Disk} GiB Boot Disk / {selectedPlan.Transfer} GiB Egress</p>
                    </div>
                  </div>
                  <h5>SELECT HOURS PER MONTH</h5>
                  <div className="flex flex-start flex-center gap-2">
                    <div className="stepper-container">
                      <div className="stepper-input-wrapper">
                          <div className="stepper-input">
                              <input 
                              type="number" 
                              className="stepper-input-field" 
                              name="gpuHours-count" 
                              placeholder="" 
                              min={minGpuHours}
                              max={maxGpuHours}
                              step="10"
                              aria-invalid="false" 
                              autoComplete="off" 
                              spellCheck="false" 
                              value={gpuHours} // Bind the input to state
                              onChange={(e) => {
                                  const value = e.target.value;

                                  // Allow the field to be empty
                                  if (value === '') {
                                  setGpuHours('');
                                  return;
                                  }

                                  const numericValue = parseInt(value, 10);

                                  // If the input is not a valid number, do nothing
                                  if (isNaN(numericValue)) {
                                  return;
                                  }

                                  setGpuHours(numericValue);
                              }}
                              onBlur={(e) => {
                                if (!selectedPlan) return; // Ensure a plan is selected before enforcing limits
                            
                                let value = parseInt(e.target.value, 10);
                            
                                // If the input is empty, reset to the minimum value without rounding up
                                if (isNaN(value)) {
                                    setGpuHours(minGpuHours);
                                    calculateTotalGpuNodePoolPrice(); // Calculate price on blur
                                    return;
                                }
                            
                                // Enforce minimum and maximum constraints without rounding up
                                if (value <= minGpuHours) {
                                    setGpuHours(minGpuHours);
                                    calculateTotalGpuNodePoolPrice(); // Calculate price on blur
                                } else if (value >= maxGpuHours) {
                                    setGpuHours(maxGpuHours);
                                    calculateTotalGpuNodePoolPrice(); // Calculate price on blur
                                } else {
                                    setGpuHours(value);
                                    calculateTotalGpuNodePoolPrice(); // Calculate price on blur
                                }
                              }}
                            />
                          </div>
                      </div>
                      <div className="stepper-button-wrapper-up">
                          <button 
                              className="stepper-button-increment" 
                              onClick={handleGpuHoursIncrement}
                              disabled={gpuHours == maxGpuHours}
                          >
                              <svg className="stepper-icon-up" aria-hidden="true">
                                  <use href="#_sprite-arrow-up"></use>
                              </svg>
                          </button>
                      </div>
                      <div className="stepper-button-wrapper-down">
                          <button 
                              className="stepper-button-decrement" 
                              onClick={handleGpuHoursDecrement}
                              disabled={gpuHours == minGpuHours}
                          >
                              <svg className="stepper-icon-down" aria-hidden="true">
                                  <use href="#_sprite-arrow-down"></use>
                              </svg>
                          </button>
                      </div>
                    </div>
                    <div className='font-size-dot8'>
                      <p className="font-weight-600">Hour Range</p>
                      <p>{minGpuHours} - {maxGpuHours}</p>
                    </div>
                  </div>
                  <h5>SELECT NUMBER OF NODES</h5>
                  <div className="scaler-container">
                      <div className="scaler-input-wrapper">
                          <div className="scaler-input">
                              <input 
                              type="number" 
                              className="scaler-input-field" 
                              name="storage-count" 
                              placeholder="" 
                              min="0"
                              max="512" 
                              aria-invalid="false" 
                              autoComplete="off" 
                              spellCheck="false" 
                              value={nodes}
                              onChange={handleNodesChange}
                              onBlur={handleNodesBlur}
                            />
                          </div>
                      </div>
                      <div className="scaler-button-wrapper-up">
                          <button 
                              className="scaler-button-increment" 
                              onClick={handleNodesIncrement}
                              disabled={nodes >= 512}
                          >
                              <svg className="scaler-icon-up" aria-hidden="true">
                                  <use href="#_sprite-plus"></use>
                              </svg>
                          </button>
                      </div>
                      <div className="scaler-button-wrapper-down">
                          <button 
                              className="scaler-button-decrement" 
                              onClick={handleNodesDecrement}
                              disabled={nodes <= 1}
                          >
                              <svg className="scaler-icon-down" aria-hidden="true">
                                  <use href="#_sprite-minus"></use>
                              </svg>
                          </button>
                      </div>
                    </div>
                  <h5>SELECT GPU NODE POOL QUANTITY</h5>
                  <input
                    ref={quantityInputRef}
                    type="number"
                    className="input"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    min="1"
                    max="10000"
                  />
                </div>
              )}
            </div>
          )}
          {activeTab === 'High Availability' && (
            <div>
              <div className="font-blueGray font-weight-400">
                High Availability costs $40 per month per cluster.
              </div>
              <h5>SELECT QUANTITY OF HIGHLY AVAILABLE CLUSTERS</h5>
              <input
                ref={quantityInputRef}
                type="number"
                className="input"
                value={quantityHighAvailability}
                onChange={handleQuantityHighAvailabilityChange}
                onBlur={handleQuantityHighAvailabilityBlur}
                min="1"
                max="10000"
              />
            </div>
          )}

        </div>
        <div id="modal-footer" className="modal-footer flex flex-between flex-center">
          <div id="action-button" className="action-button">
            <button className="button-blue" disabled={!selectedPlan && activeTab === 'Node Pools' || !selectedPlan && activeTab === 'GPU Node Pools'} 
              onClick={() => {
                if (activeTab === 'Node Pools' && selectedPlan) {
                  handleAddNodePool();
                } else if (activeTab === 'GPU Node Pools' && selectedPlan) {
                  handleAddGpuNodePool();
                } else if (activeTab === 'High Availability') {
                  handleAddHighAvailability();
                }
              }}
            >
              {activeTab === 'Node Pools' && <span>Add Node Pools</span>}
              {activeTab === 'GPU Node Pools' && <span>Add GPU Node Pools</span>}
              {activeTab === 'High Availability' && <span>Add High Availability</span>}
            </button>
          </div>
          <div id="price" className="price">
            <span className="dollar dollar-large">$</span>
            <span className="cost cost-large">
              {
                activeTab === 'Node Pools' ? (totalNodePoolPrice ? formatPrice(totalNodePoolPrice) : '0') :
                activeTab === 'GPU Node Pools' ? (totalGpuNodePoolPrice ? formatPrice(totalGpuNodePoolPrice) : '0') :
                activeTab === 'High Availability' ? (quantityHighAvailability ? formatPrice(40 * quantityHighAvailability) : '0') :
                '0'
              }
            </span>
            <span className="period period-large">/mo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KubernetesModal;
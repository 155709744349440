import React, { useState, useRef } from 'react';
import './VolumesModal.css';

function VolumesModal({ closeModal, addQuoteItem, formatPrice }) {
  const [quantityVolumes, setQuantityVolumes] = useState(1);
  const [name, setName] = useState(''); 
  const quantityInputRef = useRef(null);
  const [storageVolumes, setStorageVolumes] = useState('');

  // Price per GiB
  const pricePerGiB = 0.1;

  // Conversion factor from GiB to GB
  const conversionFactorGiBtoGB = 1.0737;

  // Conversion factor from TiB to TB
  const conversionFactorTiBtoTB = 1.0995;

  // Conversion factor from PiB to PB
  const conversionFactorPiBtoPB = 1.1259;

  // Handle quantity change
  const handleQuantityVolumesChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number(value) <= 1000)) {
      setQuantityVolumes(value);
    }
  };

  // Handle quantity blur
  const handleQuantityVolumesBlur = () => {
    if (quantityVolumes === '' || quantityVolumes < 1) {
      setQuantityVolumes(1);
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  };

  // Handle input change for storage volumes with validation
  const handleStorageVolumesChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Only allow digits
      const numValue = parseInt(value, 10);
      if (!isNaN(numValue) && numValue >= 1 && numValue <= 16384) {
        setStorageVolumes(value);
      } else if (value === '') {
        setStorageVolumes('');
      }
    }
  };

  const handleAddVolumes = () => {
    const parsedStorageVolumes = parseInt(storageVolumes, 10);
    if (!isNaN(parsedStorageVolumes) && parsedStorageVolumes > 0 && parsedStorageVolumes <= 16384) {
      const volumeDescription = `Block storage volume of ${parsedStorageVolumes.toLocaleString()} GiB (${(parsedStorageVolumes * conversionFactorGiBtoGB).toLocaleString(undefined, {maximumFractionDigits: 0})} GB).`;
      let features = '';

      addQuoteItem({
        serviceType: 'Volumes',
        name: name, 
        description: volumeDescription,
        features: features,
        priceMonthly: formatPrice(parsedStorageVolumes * pricePerGiB), 
        quantity: quantityVolumes,
        totalPrice: formatPrice(parsedStorageVolumes * pricePerGiB * quantityVolumes)
      });
      closeModal(); 
    }
  };  

  return (
    <div id="modal" className="modal volumes-modal">
      <div id="modal-content" className="modal-content">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="modal-header" className="modal-header flex flex-between flex-center">
          <div>
            <h1>
              Add Volumes
            </h1>
          </div>
          <div id="modal-item-name" className="modal-item-name">
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter item name or description"
              maxLength="50" 
            />
          </div>
        </div>
        <div id="modal-body" className="modal-body">
          <div>
            <h5>SELECT VOLUME SIZE</h5>
            <div id="calculation-container" className="calculation-container">
              <div id="calculation-container-price" className="calculation-container-price flex">
                <div className="price">
                  <span className="dollar dollar-large">$</span>
                  <span className="cost cost-large">{storageVolumes ? formatPrice(parseInt(storageVolumes, 10) * pricePerGiB) : '0'}</span>
                  <span className="period period-large">/mo</span>
                </div>
                <div>
                  <span>
                    {storageVolumes === "" 
                      ? "_ GiB ( _ GB)"
                      : storageVolumes >= 1024
                        ? `${((storageVolumes) / 1024).toFixed(2).replace(/\.0+$/, '')} TiB (${((storageVolumes) / 1024 * conversionFactorTiBtoTB).toFixed(2).replace(/\.0+$/, '')} TB)`
                        : `${(storageVolumes)} GiB (${(storageVolumes * conversionFactorGiBtoGB).toFixed(0)} GB)`
                    }
                  </span>
                </div>
                <div><span></span></div>
              </div>
              <div id="calculation-container-input" className="calculation-container-input">
                <input 
                  type="text" 
                  spellCheck="false" 
                  required 
                  placeholder="Enter volume size in GiB"  
                  value={storageVolumes}
                  onChange={handleStorageVolumesChange} // Use updated handler
                />
                <div id="floatlabel-price" className="floatlabel-price">
                  <label>Enter volume size in GiB</label>
                </div>
              </div>
            </div>
            <h5>SELECT VOLUMES QUANTITY</h5>
            <input
              ref={quantityInputRef}
              type="number"
              className="input"
              value={quantityVolumes}
              onChange={handleQuantityVolumesChange}
              onBlur={handleQuantityVolumesBlur}
              min="1"
              max="1000"
            />
          </div>
        </div>
        <div id="modal-footer" className="modal-footer flex flex-between flex-center">
          <div id="action-button" className="action-button">
            <button
              className="button-blue"
              onClick={handleAddVolumes}
              disabled={!storageVolumes || storageVolumes <= 0} 
            >
              Add Volumes
            </button>
          </div>
          <div id="price" className="price">
            <span className="dollar dollar-large">$</span>
            <span className="cost cost-large">
              {storageVolumes ? formatPrice(parseInt(storageVolumes, 10) * pricePerGiB * quantityVolumes) : '0'}
            </span>
            <span className="period period-large">/mo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VolumesModal;
import React, { useState, useEffect } from 'react';
import './App.css';
import './Modal.css';
import { useFetchPlans } from './fetchPlans';
import DropletsModal from './modals/DropletsModal';
import KubernetesModal from './modals/KubernetesModal';
import AppPlatformModal from './modals/AppPlatformModal';
import VolumesModal from './modals/VolumesModal';
import SpacesModal from './modals/SpacesModal';
import LoadBalancersModal from './modals/LoadBalancersModal';
import DatabasesModal from './modals/DatabasesModal';
import SupportModal from './modals/SupportModal';
import GenericModal from './modals/GenericModal';
import GenerateQuoteModal from './modals/GenerateQuoteModal';

// Conversion factor from TiB to TB
const conversionFactorTiBtoTB = 1.0995;

function App() {
  const [quoteItems, setQuoteItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceAfterDiscount, setTotalPriceAfterDiscount] = useState(0);
  const [totalTraffic, setTotalTraffic] = useState(0);
  const [totalVCPU, setTotalVCPU] = useState(0);
  const [totalMemory, setTotalMemory] = useState(0);
  const [totalBlockStorage, setTotalBlockStorage] = useState(0);
  const [modal, setModal] = useState(null);
  const [discount, setDiscount] = useState('0%');
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [importQuoteId, setImportQuoteId] = useState('');


  useEffect(() => {
    // Access the query string from the current URL
    const queryParams = new URLSearchParams(window.location.search);

    // Extract the 'quoteId' parameter from the query string
    const importQuoteId = queryParams.get('quoteId');

    // If 'quoteId' is present in the URL, update the state
    if (importQuoteId && quoteItems.length === 0) { // Check if quoteItems is empty
      setImportQuoteId(importQuoteId);
      handleRetrieveQuote(importQuoteId);
    }
  }, []); // Empty dependency array means this effect runs only once when the component mounts

  // Function to retrieve the quote from backend and populate variables
  const handleRetrieveQuote = async (quoteId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/retrieve-quote/${quoteId}`);
      if (!response.ok) throw new Error('Failed to retrieve quote');
      const quoteData = await response.json();
  
      setQuoteItems([]);
      setCompanyName(quoteData.companyName);
  
      quoteData.quoteItems.forEach(item => {
        addQuoteItem(item);
      });
  
      console.log('Quote retrieved and state updated successfully.');
    } catch (error) {
      console.error('Error retrieving the quote:', error);
    }
  };
  
  // Fetch pricing plans using the custom hook
  const { plansDroplets, plansDatabases, plansAppPlatform } = useFetchPlans(); 

  // Console log the plans for debugging purposes
  React.useEffect(() => {
    console.log("Droplet Plans:", plansDroplets);
    console.log("Database Plans:", plansDatabases);
    console.log("AppPlatform Plans:", plansAppPlatform);
  }, [plansDroplets, plansDatabases, plansAppPlatform]); // Dependencies ensure logs update when plans change
  


  const openModal = (service) => {
    setModal(service);
  };

  const closeModal = () => {
    setModal(null);
  };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };
  
  const formatPrice = (price) => {
    return price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  

  const addQuoteItem = (item) => {
    // Format prices
    item.priceMonthly = formatPrice(item.priceMonthly);
    item.totalPrice = formatPrice(item.totalPrice);
    
    // Update state in a way that ensures it always appends to the latest state
    setQuoteItems(prevItems => {
      const newQuoteItems = [...prevItems, item];
      console.log('Added Quote Item:', newQuoteItems);
      
      // Update total price and traffic
      updateTotalPriceAndTotalResources(newQuoteItems);
      
      // Calculate the new total price and apply the discount
      const newTotalPrice = newQuoteItems.reduce((acc, curr) => acc + parseFloat(curr.totalPrice.replace(/,/g, '')), 0);
      setTotalPriceAfterDiscount(formatPrice(newTotalPrice * (1 - discount.replace('%', '') / 100)));
      
      return newQuoteItems;
    });
  };
  
  const removeQuoteItem = (index) => {
    const updatedQuoteItems = [...quoteItems];
    const removedItem = updatedQuoteItems.splice(index, 1)[0];
    setQuoteItems(updatedQuoteItems);
    console.log('Removed Quote Item:', updatedQuoteItems);
    updateTotalPriceAndTotalResources(updatedQuoteItems);
    // Calculate the new total price and apply the discount
    const newTotalPrice = updatedQuoteItems.reduce((acc, curr) => acc + parseFloat(curr.totalPrice.replace(/,/g, '')), 0);
    setTotalPriceAfterDiscount(Number((newTotalPrice * (1 - discount.replace('%', '') / 100)).toFixed(2)));
  };

  const duplicateQuoteItem = (index) => {
    const itemToDuplicate = quoteItems[index];
    const newQuoteItems = [...quoteItems];
    newQuoteItems.splice(index + 1, 0, { ...itemToDuplicate });
    setQuoteItems(newQuoteItems);
    console.log('Duplicated Quote Item:', newQuoteItems);
    updateTotalPriceAndTotalResources(newQuoteItems);
    // Calculate the new total price and apply the discount
    const newTotalPrice = newQuoteItems.reduce((acc, curr) => acc + parseFloat(curr.totalPrice.replace(/,/g, '')), 0);
    setTotalPriceAfterDiscount(Number((newTotalPrice * (1 - discount.replace('%', '') / 100)).toFixed(2)));
  };

  const moveItemUp = (index) => {
    if (index > 0) {
      const updatedQuoteItems = [...quoteItems];
      const temp = updatedQuoteItems[index - 1];
      updatedQuoteItems[index - 1] = updatedQuoteItems[index];
      updatedQuoteItems[index] = temp;
      setQuoteItems(updatedQuoteItems);
    }
  };
  
  const moveItemDown = (index) => {
    if (index < quoteItems.length - 1) {
      const updatedQuoteItems = [...quoteItems];
      const temp = updatedQuoteItems[index + 1];
      updatedQuoteItems[index + 1] = updatedQuoteItems[index];
      updatedQuoteItems[index] = temp;
      setQuoteItems(updatedQuoteItems);
    }
  };  

  const updateTotalPriceAndTotalResources = (items) => {
    let newTotalPrice = 0;
    let newTotalTraffic = 0;
    let newTotalVCPU = 0;
    let newTotalMemory = 0;
    let newTotalBlockStorage = 0;

    items.forEach(item => {
      newTotalPrice += parseFloat(item.totalPrice.replace(/,/g, ''));
      if (['Droplets', 'Kubernetes Node Pools', 'Droplet and Kubernetes Egress Overage'].includes(item.serviceType)) {
        newTotalTraffic += (parseFloat(item.quantity) * parseFloat(item.transfer));
      }
    });
    setTotalPrice(Number(newTotalPrice.toFixed(2)));
    setTotalPriceAfterDiscount(Number((newTotalPrice * (1 - discount / 100)).toFixed(2)));
    setTotalTraffic(newTotalTraffic);
    console.log('Updated Total Price and Total Resources:', { totalPrice: newTotalPrice, totalTraffic: newTotalTraffic });
  };

  const handleQuantityChange = (index, newQuantity) => {
    if (newQuantity === '' || (newQuantity >= 1 && newQuantity <= 10000)) {
      const updatedQuoteItems = [...quoteItems];
      const item = updatedQuoteItems[index];
      const oldQuantity = item.quantity;
      item.quantity = newQuantity === '' ? '' : newQuantity;
      item.totalPrice = formatPrice(newQuantity * parseFloat(item.priceMonthly.replace(/,/g, '')));
      setQuoteItems(updatedQuoteItems);
      console.log('Quantity Changed:', updatedQuoteItems);

      const quantityDifference = newQuantity - oldQuantity;
      const newTotalPrice = totalPrice + (quantityDifference * parseFloat(item.priceMonthly.replace(/,/g, '')));
      setTotalPrice(Number(newTotalPrice.toFixed(2)));

      // Apply discount after updating total price
      setTotalPriceAfterDiscount(Number((newTotalPrice * (1 - discount.replace('%', '') / 100)).toFixed(2)));

      if (['Droplets', 'Kubernetes Node Pools', 'Droplet and Kubernetes Egress Overage'].includes(item.serviceType)) {
        const newTotalTraffic = totalTraffic + (quantityDifference * parseFloat(item.transfer));
        setTotalTraffic(newTotalTraffic);
      }
    }
  };

  const handleQuantityBlur = (index) => {
    const updatedQuoteItems = [...quoteItems];
    const item = updatedQuoteItems[index];
    if (item.quantity === '' || item.quantity < 1) {
      item.quantity = 1;
      item.totalPrice = formatPrice(1 * parseFloat(item.priceMonthly.replace(/,/g, '')));
      setQuoteItems(updatedQuoteItems);
      console.log('Quantity Blur:', updatedQuoteItems);
      updateTotalPriceAndTotalResources(updatedQuoteItems);
    }
  };

  const handleDiscountChange = (e) => {
    let value = e.target.value.replace('%', ''); // Remove existing '%' if any
    value = value.replace(/^0+/, ''); // Remove leading zeros
    if (value === '') {
      setDiscount(''); // If the value is empty, set the discount to an empty string
    } else if (value >= 0 && value <= 100) {
      setDiscount(value + '%'); // Ensure the '%' symbol is included
    }
  };

  const handleDiscountBlur = () => {
    if (discount === '' || discount.replace('%', '') < 0) {
      setDiscount('0%'); // Default to '0%' if the input is invalid
    } else if (!discount.includes('%')) {
      setDiscount(discount + '%'); // Ensure the '%' symbol is included
    }
  };

  const handleDiscountFocus = (e) => {
    e.target.select();
  };

  const applyDiscount = () => {
    const discountValue = parseFloat(discount.replace('%', '')); // Extract numerical value from discount
    if (isDiscountApplied) {
      // If discount is already applied, remove it
      setIsDiscountApplied(false);
      setDiscount('0%'); // Reset discount to '0%'
      setTotalPriceAfterDiscount(Number(totalPrice.toFixed(2))); // Reset total price after discount
    } else {
      // Apply discount
      setIsDiscountApplied(true);
      setTotalPriceAfterDiscount(Number((totalPrice * (1 - discountValue / 100)).toFixed(2))); // Calculate new total price after discount
    }

  };

  return (
    <div id="App" className="App">
      <div class="hidden">
        <svg xmlns="http://www.w3.org/2000/svg">
          <symbol viewBox="0 0 16 17" id="_sprite-copy" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 2h8V1a1 1 0 00-1-1H2a1 1 0 00-1 1v9.333a1 1 0 001 1h1V2z"></path>
            <path d="M7 6h8V5a1 1 0 00-1-1H6a1 1 0 00-1 1v9.333a1 1 0 001 1h1V6z"></path>
            <path d="M13.01 14l-8 .01.002.999a1 1 0 001.001.998l8-.01a1 1 0 00.999-1l-.011-9.33A1 1 0 0014 4.67h-1l.01 9.33z"></path>
          </symbol>
          <symbol fill="none" viewBox="0 0 16 16" id="_sprite-slideout" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.5" y="3" width="11" height="10" rx="1.5" fill="#fff" stroke="currentColor"></rect>
            <path d="M5.5 12H4a1.5 1.5 0 01-1.5-1.5v-5A1.5 1.5 0 014 4h1.5v8z" fill="currentColor" stroke="currentColor"></path>
            <rect x="7.25" y="5.75" width="3.5" height=".5" rx=".25" fill="currentColor" stroke="currentColor" stroke-width=".5"></rect>
            <rect x="7.25" y="7.75" width="1.5" height=".5" rx=".25" fill="currentColor" stroke="currentColor" stroke-width=".5"></rect>
            <rect x="10.25" y="7.75" width="1.5" height=".5" rx=".25" fill="currentColor" stroke="currentColor" stroke-width=".5"></rect>
            <rect x="7.25" y="9.75" width="2.5" height=".5" rx=".25" fill="currentColor" stroke="currentColor" stroke-width=".5"></rect>
          </symbol>
          <symbol viewBox="0 0 32 32" id="_sprite-arrow-up" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.04 21.952l-13.824-14.4c-.64-.64-1.856-.64-2.432 0L.96 21.952a1.862 1.862 0 000 2.496c.32.32.768.512 1.28.512s.896-.192 1.216-.512l12.608-13.12 12.608 13.12c.32.32.704.512 1.152.512.32 0 .64-.128 1.152-.512.704-.704.704-1.856.064-2.496z"></path>
          </symbol>
          <symbol viewBox="0 0 32 32" id="_sprite-arrow-down" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.296 7.68c-.256-.32-.704-.512-1.216-.512-.448 0-.896.192-1.216.512L16 20.736 3.2 7.68c-.256-.32-.704-.512-1.216-.512-.448 0-.896.192-1.216.448S.256 8.32.256 8.768c0 .512.192.96.512 1.28L14.72 24.32c.32.32.704.512 1.152.512.256 0 .512-.064.768-.192.128-.064.256-.128.384-.128l.128-.064 14.016-14.336c.768-.64.704-1.728.128-2.432z"></path>
          </symbol>
          <symbol viewBox="0 0 32 32" id="_sprite-minus" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.6 14.16h28.8c.88 0 1.6.72 1.6 1.6v.56c0 .88-.72 1.6-1.6 1.6H1.6c-.88 0-1.6-.72-1.6-1.6v-.56c0-.88.72-1.6 1.6-1.6z">
            </path>
          </symbol>
          <symbol viewBox="0 0 32 32" id="_sprite-plus" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.6 14.16h28.8c.88 0 1.6.72 1.6 1.6v.56c0 .88-.72 1.6-1.6 1.6H1.6c-.88 0-1.6-.72-1.6-1.6v-.56c0-.88.72-1.6 1.6-1.6z">
            </path>
            <path
                d="M15.76 0h.56c.88 0 1.6.72 1.6 1.6v28.8c0 .88-.72 1.6-1.6 1.6h-.56c-.88 0-1.6-.72-1.6-1.6V1.6c0-.88.72-1.6 1.6-1.6z">
            </path>
          </symbol>
        </svg>
      </div>
      <aside id="sidebar" className="sidebar">
        <div id="logo" className="logo" onClick={() => window.location.href = process.env.REACT_APP_DOMAIN}></div>
        <h5>ADD SERVICE</h5>
        <ul>
          <li><a href="#" onClick={event => { event.preventDefault(); openModal("Droplets") }}>Droplets</a></li>
          <li><a href="#" onClick={event => { event.preventDefault(); openModal("Kubernetes") }}><span>Kubernetes</span></a></li>
          <li><a href="#" onClick={event => { event.preventDefault(); openModal("App Platform") }}><span>App Platform</span></a></li>
          <li><a href="#" onClick={event => { event.preventDefault(); openModal("Load Balancers") }}><span className="">Load Balancers</span></a></li>
          <li><a href="#" onClick={event => { event.preventDefault(); openModal("Volumes") }}><span>Volumes</span></a></li>
          <li><a href="#" onClick={event => { event.preventDefault(); openModal("Spaces") }}><span>Spaces</span></a></li>
          <li><a href="#" onClick={event => { event.preventDefault(); openModal("Databases") }}><span>Databases</span></a></li>
          <li><a href="#" onClick={event => { event.preventDefault(); openModal("Support Plans") }}><span>Support Plans</span></a></li>
        </ul>
      </aside>
      <div id="main-content" className="main-content">
        <header>
          <div>DigitalOcean Commercial Workspace</div>
        </header>
        <div id="content-area" className="content-area">
          <div id="content-area-header" className="content-area-header">
            <div>
              <h1>Estimate Preview</h1>
            </div>
            <div id="company-name" className="company-name">
              <input 
                type="text" 
                placeholder="Enter Company Name" 
                className="input" 
                maxLength="50" 
                value={companyName} // Ensure this value is set from the state
                onChange={handleCompanyNameChange} 
              />
            </div>
          </div>
          <section id="current-quote" className="quote">
            <div id="quoteItems">
              {quoteItems.length === 0 ? (
                <p id="quote-placeholder" className="quote-placeholder">No items in the estimate. Please add a service to get started.</p>
              ) : (
                quoteItems.map((item, index) => (
                  <div key={index} id="quote-item" className="quote-item">
                    <div id="quote-item-header" className="quote-item-header flex flex-between flex-center">
                      <div id="quote-item-index" className="quote-item-index">Item {index + 1}</div>
                      <div id="quote-item-service" className="quote-item-service"><strong>{item.serviceType}</strong></div>
                      <div id="quote-item-name" className="quote-item-name">{item.name}</div>
                      <div id="quote-item-action-buttons" className="quote-item-action-buttons">
                        <div id="action-text" className="action-text" onClick={() => moveItemUp(index)}>
                          <svg className="icon-up" aria-hidden="true">
                            <use href="#_sprite-arrow-up"></use>
                          </svg>
                        </div>
                        <div id="action-text" className="action-text" onClick={() => moveItemDown(index)}>
                          <svg className="icon-down" aria-hidden="true">
                            <use href="#_sprite-arrow-down"></use>
                          </svg>
                        </div>
                        <div id="action-text" className="action-text" onClick={() => duplicateQuoteItem(index)}>Clone</div>
                        <div id="action-text" className="action-text" onClick={() => removeQuoteItem(index)}>Delete</div>
                      </div>
                    </div>
                    <div id="quote-item-body" className="quote-item-body">
                      <div id="quote-item-description" className="quote-item-description flex flex-between flex-center">
                        <div>{item.description}</div>
                          <div id="price" className="price">
                            <span className="dollar">$</span>
                            <span className="cost">{formatPrice(item.totalPrice)}</span>
                            <span className="period">/mo</span>
                          </div>
                      </div>
                      {item.features && (
                        <div id="quote-item-features" className="quote-item-features">
                          {item.features}
                          </div>
                      )}
                      <div id="quote-item-quantity" className="quote-item-quantity">
                        <input
                          type="number"
                          value={item.quantity}
                          onChange={(e) => handleQuantityChange(index, parseInt(e.target.value) || '')}
                          onBlur={() => handleQuantityBlur(index)}
                          min="1"
                          max="10000"
                          style={{ width: '50px' }}
                        />
                        at ${formatPrice(item.priceMonthly)} /mo.
                      </div>

                    </div>
                  </div>
                ))
              )}
            </div>
          </section>
        </div>
        <div id="footer-container" className="footer-container">
          <div id="quote-totals" className="quote-totals">
            <p className="header-footer-text-large">$<span id="totalPrice">{formatPrice(totalPriceAfterDiscount)}</span> /month</p>
            {isDiscountApplied && (
             <p className="header-footer-text-small">$<span id="totalPriceAfterDiscount">{formatPrice(totalPrice)}</span> /month before discount.</p>
            )}
            <p className="header-footer-text-small"><span>{totalTraffic.toLocaleString(undefined, {maximumFractionDigits: 0})} TiB ({(totalTraffic * conversionFactorTiBtoTB).toLocaleString(undefined, {maximumFractionDigits: 0})} TB) egress.</span></p>
          </div>
          <div id="quote-discounts" className="quote-discounts">
            <input
              type="text"
              className="input"
              value={discount} 
              onChange={handleDiscountChange}
              onBlur={handleDiscountBlur}
              onFocus={handleDiscountFocus}
              style={{ width: '75px', marginLeft: '10px' }}
              disabled={isDiscountApplied || quoteItems.length === 0} // Disable input if discount is applied or quote is empty
            />
            <button
              className="button-blue-transparent"
              id="applyDiscount"
              onClick={applyDiscount}
              style={{ width: '160px' }}
              disabled={discount === '0%' || quoteItems.length === 0} // Disable button if discount is '0%' or quote is empty
            >
              {isDiscountApplied ? 'Remove Discount' : 'Apply Discount'}
            </button>
          </div>
          <div id="controls" className="controls">
            <button 
              className="button-green" 
              onClick={event => { event.preventDefault(); openModal("Generate Quote") }}
              disabled={quoteItems.length === 0} // Disable button if quote is empty
            >
              Create Estimate
            </button>
          </div>
        </div>
      </div>
      {modal === 'Droplets' && <DropletsModal closeModal={closeModal} addQuoteItem={addQuoteItem} formatPrice={formatPrice} plansDroplets={plansDroplets} />}
      {modal === 'Kubernetes' && <KubernetesModal closeModal={closeModal} addQuoteItem={addQuoteItem} formatPrice={formatPrice} plansDroplets={plansDroplets} />}
      {modal === 'App Platform' && <AppPlatformModal closeModal={closeModal} addQuoteItem={addQuoteItem} formatPrice={formatPrice} plansAppPlatform={plansAppPlatform} />}
      {modal === 'Volumes' && <VolumesModal closeModal={closeModal} addQuoteItem={addQuoteItem} formatPrice={formatPrice} />}
      {modal === 'Spaces' && <SpacesModal closeModal={closeModal} addQuoteItem={addQuoteItem} formatPrice={formatPrice} />}
      {modal === 'Load Balancers' && <LoadBalancersModal closeModal={closeModal} addQuoteItem={addQuoteItem} formatPrice={formatPrice} />}
      {modal === 'Databases' && <DatabasesModal closeModal={closeModal} addQuoteItem={addQuoteItem} formatPrice={formatPrice} plansDatabases={plansDatabases} />}
      {modal === 'Support Plans' && <SupportModal closeModal={closeModal} addQuoteItem={addQuoteItem} formatPrice={formatPrice}/>}
      {modal === 'Generic' && <GenericModal closeModal={closeModal} addQuoteItem={addQuoteItem} />}
      {modal === 'Generate Quote' && (
        <GenerateQuoteModal
          closeModal={closeModal}
          quoteItems={quoteItems}
          formatPrice={formatPrice}
          companyName={companyName}
          totalPrice={totalPrice}
          totalPriceAfterDiscount={totalPriceAfterDiscount}
          totalTraffic={totalTraffic}
          isDiscountApplied={isDiscountApplied}
          discount={discount}
        />
      )}
      {/* Add more modals for other services as needed */}
    </div>
  );
}

export default App;
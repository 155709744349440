import React, { useState, useRef, useEffect } from 'react';

function TabMongoDB({ name, addQuoteItem, formatPrice, plansDatabases, closeModal }) {
  const [quantity, setQuantity] = useState(1);
	const [standbyNodes, setStandbyNodes] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const searchInputRef = useRef(null);
  const quantityInputRef = useRef(null);

  // Focus and select the quantity input when a plan is selected
  useEffect(() => {
    if (selectedPlan) {
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  }, [selectedPlan]);

  // Filter plans based on the search term for MongoDB
  const filteredPlans = plansDatabases.filter(plan => {
    if (!plan.mongodbNodes) {
      return false;
    }
    // Create a regex from the search term, escaping special characters to prevent regex errors
    const regex = new RegExp(searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    return (
      regex.test(plan.Description) ||
      regex.test(plan.Slug) ||
      regex.test(`${plan.VCPUs} vCPU`) ||
      regex.test(`${plan.Memory} GiB Memory`) ||
      regex.test(`${plan.mongodbStorageMin} GiB Disk`) ||
      regex.test(plan.mongodbNVMe) ||
      regex.test(`${plan.Network} Gbps Network`) ||
      regex.test(`$${plan.mongodbPriceMin} /mo`)
    );
  });

  // Handle plan selection for MongoDB
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setShowDropdown(false);
    setSearchTerm('');
    calculateTotalPrice(plan, 0); // Calculate price immediately when plan is selected
  };

  const calculateTotalPrice = (plan, standbyNodes) => {
    if (!plan) return;
    let totalPrice = plan.mongodbPriceMin * (1 + standbyNodes);
    setTotalPrice(formatPrice(totalPrice));
  };

	// Function to increment Standby Nodes by 2, capped at the maximum limit
	const handleStandbyNodesIncrement = () => {
    const maxNodes = selectedPlan.mongodbNodes === "[1,3]" ? 2 : 0;
    setStandbyNodes(prevNodes => {
        let newNodes = prevNodes < maxNodes ? prevNodes + 2 : prevNodes;
        calculateTotalPrice(selectedPlan, newNodes);
        return newNodes;
    });
	};

	// Function to decrement Standby Nodes by 1, capped at the minimum limit
	const handleStandbyNodesDecrement = () => {
    const minNodes = 0;
    setStandbyNodes(prevNodes => {
        let newNodes = prevNodes > minNodes ? prevNodes - 2 : prevNodes;
        calculateTotalPrice(selectedPlan, newNodes);
        return newNodes;
    });
	};

	// Handle Standby Nodes change for MongoDB
	const handleStandbyNodesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (isNaN(value) || value < 0 || value > 2) {
        setStandbyNodes(0);
        calculateTotalPrice(selectedPlan, 0); // Recalculate price on input change
    } else {
        setStandbyNodes(value);
        calculateTotalPrice(selectedPlan, value); // Recalculate price on input change
    }
	};
	
  // Handle Standby Nodes blur for MongoDB
  const handleStandbyNodesBlur = () => {
    // Allow 2 nodes if the selected plan's configuration allows it, otherwise default to 0
    let maxNodesAllowed = selectedPlan.mongodbNodes === "[1,3]" ? 2 : 0;
    // Ensure standbyNodes is either 0 or 2, strictly avoid 1 or any non-valid values
    let validNodes = standbyNodes === 1 ? 0 : Math.min(Math.max(0, standbyNodes), maxNodesAllowed);
    
    setStandbyNodes(validNodes); // Adjust to valid number of nodes on blur
    calculateTotalPrice(selectedPlan, validNodes); // Recalculate price on blur
  };

	// Handle quantity change for MongoDB
	const handleQuantityChange = (e) => {
		const value = e.target.value;
		if (value === '' || (Number(value) >= 1 && Number(value) <= 1000)) {
			setQuantity(value);
		}
	};

	// Handle quantity blur for MongoDB
	const handleQuantityBlur = () => {
		if (quantity === '' || quantity < 1) {
			setQuantity(1);
			quantityInputRef.current.focus();
			quantityInputRef.current.select();
		}
	};

  // Handle adding the selected MongoDB plan to the quote
  const handleAdd = () => {
    if (selectedPlan) {
			let description = `${selectedPlan.Description} - ${selectedPlan.VCPUs} vCPU / ${selectedPlan.Memory} GiB Memory / ${selectedPlan.mongodbStorageMin} GiB ${(selectedPlan.mongodbNVMe === 'nvme' ? 'NVMe local storage' : 'SSD local storage')}.`;
      let features = '';
			if (standbyNodes === 0) {
				features += 'Single-node deployment. ';
			} else if (standbyNodes === 2) {
					features += 'Replica set deployment with 3 nodes. ';
			}

    	// Append the number of nodes to the description if standbyNodes is greater than 0
    	if (standbyNodes > 0) {
      	description = `${standbyNodes + 1}x ` + description;
    	}	

      addQuoteItem({
        serviceType: 'Databases - MongoDB',
        name: name,
        description: description,
        features: features,
        transfer: '',
        priceMonthly: totalPrice,
        quantity: quantity || 1,
        totalPrice: Number(totalPrice.toString().replace(/,/g, '')) * (quantity || 1)
    });
      closeModal(); // Close the modal after adding the quote item
    }
  };

  return (
    <div id="modal-body" className="modal-body">
      <h5>SELECT DATABASE PLAN</h5>
      <div id="search-container" className="search-container">
        <input
          ref={searchInputRef}
          type="text"
          className="input"
          placeholder="Search MongoDB plans or click to view all"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setShowDropdown(true);
          }}
          onClick={() => setShowDropdown(!showDropdown)}
        />
        {showDropdown && filteredPlans.length > 0 && (
          <ul id="dropdown" className="dropdown">
            {filteredPlans.map((plan, index) => (
              <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray">
                <div className="dropdown-plans-description">
                  {plan.Description}
                </div>
                <div className="dropdown-plans-specifications">
                  {plan.VCPUs} vCPU, {plan.Memory} GiB Memory, {plan.mongodbStorageMin} GiB Disk
                </div>
                <div id="price" className="price">
                  <span className="dollar">$</span>
                  <span>{formatPrice(parseInt(plan.mongodbPriceMin, 10))}</span>
                  <span className="period">/mo</span>
                </div>
              </li>
            ))}
          </ul>
        )}
        {showDropdown && filteredPlans.length === 0 && (
          <ul id="dropdown" className="dropdown">
            <li>No plans found</li>
          </ul>
        )}
      </div>
      {selectedPlan && (
        <div>
          <div id="card" className="card">
            <div id="card-header" className="card-header flex flex-between flex-center">
              <div>
                <h5>SELECTED PLAN</h5>
              </div>
              <div id="price" className="price">
                <span className="dollar">$</span>
                <span className="cost">{formatPrice(totalPrice)}</span>
                <span className="period">/mo</span>
              </div>
            </div>
            <div id="card-body" className="card-body">
              <p>{selectedPlan.Description}</p>
              <p>{selectedPlan.VCPUs} vCPU / {selectedPlan.Memory} GiB Memory / {selectedPlan.mongodbStorageMin} GiB Disk</p>
            </div>
          </div>

					<h5>SELECT STANDBY NODES</h5>
					<div className="scaler-container">
							<div className="scaler-input-wrapper">
									<div className="scaler-input">
											<input 
											type="number" 
											className="scaler-input-field" 
											name="storage-count" 
											placeholder="" 
											min="0"
											max={selectedPlan.mongodbNodes == "[1,2,3]" ? 2 : 0} 
											aria-invalid="false" 
											autoComplete="off" 
											spellCheck="false" 
											value={standbyNodes}
											onChange={handleStandbyNodesChange}
											onBlur={handleStandbyNodesBlur}
											disabled={selectedPlan.mongodbNodes == "[1]"}
										/>
									</div>
							</div>
							<div className="scaler-button-wrapper-up">
									<button 
											className="scaler-button-increment" 
											onClick={handleStandbyNodesIncrement}
											disabled={standbyNodes >= 2 || selectedPlan.mongodbNodes === "[1]"} // Disable if current standbyNodes is 2 or if the plan supports only 1 node
									>
											<svg className="scaler-icon-up" aria-hidden="true">
													<use href="#_sprite-plus"></use>
											</svg>
									</button>
							</div>
							<div className="scaler-button-wrapper-down">
									<button 
											className="scaler-button-decrement" 
											onClick={handleStandbyNodesDecrement}
											disabled={standbyNodes <= 0 || selectedPlan.mongodbNodes === "[1]"} // Disable if current standbyNodes is 0 or if the plan supports only 1 node
									>
											<svg className="scaler-icon-down" aria-hidden="true">
													<use href="#_sprite-minus"></use>
											</svg>
									</button>
							</div>
						</div>

          <h5>SELECT DATABASE QUANTITY</h5>
          <input
            ref={quantityInputRef}
            type="number"
            className="input"
            value={quantity}
            onChange={handleQuantityChange}
            onBlur={handleQuantityBlur}
            min="1"
            max="1000"
          />
				</div>
      )}
			<div id="modal-footer" className="modal-footer flex flex-between flex-center">
				<div id="action-button" className="action-button">
						<button
						className="button-blue"
						onClick={handleAdd}
						disabled={!selectedPlan} // Disable the button if no plan is selected
						>
						Add MongoDB
						</button>
				</div>
				<div id="price" className="price">
						<span className="dollar dollar-large">$</span>
						<span className="cost cost-large">{selectedPlan ? formatPrice(Number(totalPrice.toString().replace(/,/g, '')) * quantity) : '0'}</span>
						<span className="period period-large">/mo</span>
				</div>
			</div>
		</div>
  );
}

export default TabMongoDB;
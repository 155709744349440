import React, { useState } from 'react';
import './GenericModal.css';

function GenericModal({ closeModal, addQuoteItem }) {
  const [activeTab, setActiveTab] = useState('Tab1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={closeModal}>&times;</span>
        <div className="modal-header">
          <h1>Add Service</h1>
        </div>
        <div className="modal-tabs">
          <button
            className={`tab-button ${activeTab === 'Tab1' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Tab1')}
          >
            Tab1
          </button>
          <button
            className={`tab-button ${activeTab === 'Tab2' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Tab2')}
          >
            Tab2
          </button>
        </div>
        <div className="modal-body">
          {activeTab === 'Tab1' && (
            <div>
              <h3>Tab1 Configuration</h3>
              <p>Configure your Tab1 settings below:</p>
              <label>
                Tab1 Size:
                <input type="text" placeholder="e.g., 2GB" />
              </label>
              <label>
                Region:
                <select>
                  <option value="nyc1">New York 1</option>
                  <option value="sfo2">San Francisco 2</option>
                  <option value="ams3">Amsterdam 3</option>
                </select>
              </label>
              <button onClick={() => addQuoteItem({ serviceType: 'Tab1', name: 'Example Tab1', quantity: 1, priceMonthly: 5.00, totalPrice: 5.00 })}>Add Tab1</button>
            </div>
          )}
          {activeTab === 'Tab2' && (
            <div>
              <h3>Tab2 Configuration</h3>
              <p>Configure your Tab2 settings below:</p>
              <label>
                Tab2 Amount:
                <input type="number" placeholder="e.g., 100GB" />
              </label>
              <label>
                Tab2 Speed:
                <select>
                  <option value="1Gbps">1 Gbps</option>
                  <option value="10Gbps">10 Gbps</option>
                  <option value="100Gbps">100 Gbps</option>
                </select>
              </label>
              <button onClick={() => addQuoteItem({ serviceType: 'Tab2', name: 'Example Tab2', quantity: 1, priceMonthly: 10.00, totalPrice: 10.00 })}>Add Tab2</button>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button className="button" onClick={closeModal}>Close</button>
        </div>
      </div>
    </div>
  );
}

export default GenericModal;
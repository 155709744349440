// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/do_logo_horizontal_blue.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generated-quote-modal {
    min-width: 70rem;
}

.generated-quote-header, .generated-quote-summary {
    margin-bottom: 3rem;
}

.generated-quote-logo {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: 170px;
    height: 29px;
    transition: opacity 0.4s linear;
    background-size: contain; /* Scale the image to fit the container */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    display: block; /* Ensure the div is a block element */
}

.generated-quote-item-export-buttons {
    justify-content: unset;
    padding: 1rem 0;
    gap: 1rem;
}

.generated-quote-dates {
    flex-direction: column;
}

.generated-quote-issue {
    width: 100%;
    gap: .8rem;
}

.generated-quote-expiry {
    width: 100%;
    gap: .8rem;
}

.generated-quote-footer {
    justify-content: space-between;
    margin-top: 4rem; 
}

.generated-quote-totals {
    margin-left: auto;
    align-items: end;
    padding: 1.5rem 1.5rem 1.5rem 6rem;
    border: solid 1px;
    border-radius: 3px;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.notification {
    background-color: rgb(91, 105, 135);
    color: #fff;
    padding: 0px 5px;
    border-radius: 3px;
    opacity: 0.9;
    z-index: 1000;
  }  `, "",{"version":3,"sources":["webpack://./src/modals/GenerateQuoteModal.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yDAAmE;IACnE,YAAY;IACZ,YAAY;IACZ,+BAA+B;IAC/B,wBAAwB,EAAE,yCAAyC;IACnE,4BAA4B,EAAE,qCAAqC;IACnE,cAAc,EAAE,sCAAsC;AAC1D;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,SAAS;AACb;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kCAAkC;IAClC,iBAAiB;IACjB,kBAAkB;IAClB,2BAAsB;IAAtB,sBAAsB;AAC1B;;AAEA;IACI,mCAAmC;IACnC,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,aAAa;EACf","sourcesContent":[".generated-quote-modal {\n    min-width: 70rem;\n}\n\n.generated-quote-header, .generated-quote-summary {\n    margin-bottom: 3rem;\n}\n\n.generated-quote-logo {\n    background-image: url('/public/assets/do_logo_horizontal_blue.svg');\n    width: 170px;\n    height: 29px;\n    transition: opacity 0.4s linear;\n    background-size: contain; /* Scale the image to fit the container */\n    background-repeat: no-repeat; /* Prevent the image from repeating */\n    display: block; /* Ensure the div is a block element */\n}\n\n.generated-quote-item-export-buttons {\n    justify-content: unset;\n    padding: 1rem 0;\n    gap: 1rem;\n}\n\n.generated-quote-dates {\n    flex-direction: column;\n}\n\n.generated-quote-issue {\n    width: 100%;\n    gap: .8rem;\n}\n\n.generated-quote-expiry {\n    width: 100%;\n    gap: .8rem;\n}\n\n.generated-quote-footer {\n    justify-content: space-between;\n    margin-top: 4rem; \n}\n\n.generated-quote-totals {\n    margin-left: auto;\n    align-items: end;\n    padding: 1.5rem 1.5rem 1.5rem 6rem;\n    border: solid 1px;\n    border-radius: 3px;\n    min-width: fit-content;\n}\n\n.notification {\n    background-color: rgb(91, 105, 135);\n    color: #fff;\n    padding: 0px 5px;\n    border-radius: 3px;\n    opacity: 0.9;\n    z-index: 1000;\n  }  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

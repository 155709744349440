// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.droplets-modal {
    min-width: 58rem;
}

.droplets-modal .calculation-container {
    width: 15rem;
}

.droplets-modal .modal-radio-buttons {
    width: 50%;
    min-width: -moz-fit-content;
    min-width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/modals/DropletsModal.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,2BAAsB;IAAtB,sBAAsB;AAC1B","sourcesContent":[".droplets-modal {\n    min-width: 58rem;\n}\n\n.droplets-modal .calculation-container {\n    width: 15rem;\n}\n\n.droplets-modal .modal-radio-buttons {\n    width: 50%;\n    min-width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

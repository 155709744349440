import React, { useState, useRef, useEffect } from 'react';

function TabOpenSearch({ name, addQuoteItem, formatPrice, plansDatabases, closeModal }) {
  const [quantity, setQuantity] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [scalableStorage, setScalableStorage] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const searchInputRef = useRef(null);
  const quantityInputRef = useRef(null);
  const [nodes, setNodes] = useState(1); // Set default to 1 Node


	// Define price per GiB for Scalable Storage
	const pricePerGiB = 0.2;

  // Focus and select the quantity input when a plan is selected
  useEffect(() => {
    if (selectedPlan) {
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  }, [selectedPlan]);

  // Filter plans based on the search term for OpenSearch
  const filteredPlans = plansDatabases.filter(plan => {
    if (!plan.opensearchNodes) {
      return false;
    }
    // Create a regex from the search term, escaping special characters to prevent regex errors
    const regex = new RegExp(searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    return (
      regex.test(plan.Description) ||
      regex.test(plan.Slug) ||
      regex.test(`${plan.VCPUs} vCPU`) ||
      regex.test(`${plan.Memory} GiB Memory`) ||
      regex.test(`${plan.opensearchStorageMin} GiB Disk`) ||
      regex.test(plan.opensearchNVMe) ||
      regex.test(`${plan.Network} Gbps Network`) ||
      regex.test(`$${plan.opensearchPriceMin} /mo`)
    );
  });

  // Handle plan selection for OpenSearch
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setScalableStorage(plan.opensearchStorageMin); // Set scalableStorage when a plan is selected
    setShowDropdown(false);
    setSearchTerm('');
    setNodes(1); // Set nodes to 1 when plan is selected or changed
    calculateTotalPrice(plan.opensearchStorageMin, plan); // Calculate price immediately when plan is selected with the default set of nodes
  };

  const calculateTotalPrice = (storage, plan, nodes) => {
    if (!plan) return 0;

    // Check if nodes is one of the allowed values, otherwise default to 1
    if (![1, 3, 6, 9, 15].includes(nodes)) {
        nodes = 1;
    }

    let basePrice = parseFloat(plan.opensearchPriceMin * nodes);
    let additionalStorageCost = (storage - plan.opensearchStorageMin * nodes) * pricePerGiB;
    let totalPrice = basePrice + additionalStorageCost;

    setTotalPrice(formatPrice(totalPrice));
};

	// Function to increment the storage, capped at the maximum limit
	const handleStorageIncrement = () => {
		const currentStorage = parseInt(scalableStorage, 10) || selectedPlan.opensearchStorageMin * nodes;
		const newStorage = Math.min(selectedPlan.opensearchStorageMax * nodes, currentStorage + 10 * nodes);
		setScalableStorage(newStorage); // Update the storage size in the state
		calculateTotalPrice(newStorage, selectedPlan, nodes); // Recalculate the total price with the new storage size
	};

	// Function to decrement the storage size, capped at the minimum limit
	const handleStorageDecrement = () => {
		const currentStorage = parseInt(scalableStorage, 10) || selectedPlan.opensearchStorageMin * nodes;
		const newStorage = Math.max(selectedPlan.opensearchStorageMin * nodes, currentStorage - 10 * nodes);
		setScalableStorage(newStorage); // Update the storage size in the state
		calculateTotalPrice(newStorage, selectedPlan, nodes); // Recalculate the total price with the new storage size
	};

	// Handle quantity change for OpenSearch
	const handleQuantityChange = (e) => {
		const value = e.target.value;
		if (value === '' || (Number(value) >= 1 && Number(value) <= 1000)) {
			setQuantity(value);
		}
	};

	// Handle quantity blur for OpenSearch
	const handleQuantityBlur = () => {
		if (quantity === '' || quantity < 1) {
			setQuantity(1);
			quantityInputRef.current.focus();
			quantityInputRef.current.select();
		}
	};

  // Handle adding the selected OpenSearch plan to the quote
  const handleAdd = () => {
    if (selectedPlan) {
			let description = `${selectedPlan.Description} - ${selectedPlan.VCPUs * nodes} vCPU / ${selectedPlan.Memory * nodes} GiB Memory / ${scalableStorage} GiB ${scalableStorage > selectedPlan.opensearchStorageMin * nodes ? 'scalable storage' : (selectedPlan.opensearchNVMe === 'nvme' ? 'NVMe local storage' : 'SSD local storage')}.`;
      let features = `Cluster with ${nodes} OpenSearch nodes.`;

      addQuoteItem({
        serviceType: 'Databases - OpenSearch',
        name: name,
        description: description,
        features: features,
        transfer: '',
        priceMonthly: totalPrice,
        quantity: quantity || 1,
        totalPrice: Number(totalPrice.toString().replace(/,/g, '')) * (quantity || 1)
    });
      closeModal(); // Close the modal after adding the quote item
    }
  };

  return (
    <div id="modal-body" className="modal-body">
      <h5>SELECT DATABASE PLAN</h5>
      <div id="search-container" className="search-container">
        <input
          ref={searchInputRef}
          type="text"
          className="input"
          placeholder="Search OpenSearch plans or click to view all"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setShowDropdown(true);
          }}
          onClick={() => setShowDropdown(!showDropdown)}
        />
        {showDropdown && filteredPlans.length > 0 && (
          <ul id="dropdown" className="dropdown">
            {filteredPlans.map((plan, index) => (
              <li key={index} onClick={() => handlePlanSelect(plan)} className="flex font-darkGray">
                <div className="dropdown-plans-description">
                  {plan.Description}
                </div>
                <div className="dropdown-plans-specifications">
                  {plan.VCPUs} vCPU, {plan.Memory} GiB Memory, {plan.opensearchStorageMin} GiB Disk
                </div>
                <div id="price" className="price">
                  <span className="dollar">$</span>
                  <span>{formatPrice(parseInt(plan.opensearchPriceMin, 10))}</span>
                  <span className="period">/mo</span>
                </div>
              </li>
            ))}
          </ul>
        )}
        {showDropdown && filteredPlans.length === 0 && (
          <ul id="dropdown" className="dropdown">
            <li>No plans found</li>
          </ul>
        )}
      </div>
      {selectedPlan && (
        <div>
          <div id="card" className="card">
            <div id="card-header" className="card-header flex flex-between flex-center">
              <div>
                <h5>SELECTED PLAN</h5>
              </div>
              <div id="price" className="price">
                <span className="dollar">$</span>
                <span className="cost">{formatPrice(totalPrice)}</span>
                <span className="period">/mo</span>
              </div>
            </div>
            <div id="card-body" className="card-body">
              <p>{selectedPlan.Description}</p>
              <p>{selectedPlan.VCPUs * nodes} vCPU / {selectedPlan.Memory * nodes} GiB Memory / {scalableStorage} GiB Disk</p>
            </div>
          </div>
          {(selectedPlan.opensearchNodes === "[1,3]" || selectedPlan.opensearchNodes === "[1,3,6,9,15]") && (
            <>
              <h5>SELECT NUMBER OF NODES</h5>
              <div className="flex gap-dot5">
                <div
                  className={`radio-button ${nodes === 1 ? 'radio-button-active' : ''}`}
                  onClick={() => { 
                    setNodes(1); 
                    setScalableStorage(selectedPlan.opensearchStorageMin);
                    setTotalPrice(selectedPlan.opensearchPriceMin);
                  }}
                >
                  <span>1 node</span>
                </div>
                <div
                  className={`radio-button ${nodes === 3 ? 'radio-button-active' : ''}`}
                  onClick={() => { 
                    setNodes(3); 
                    setScalableStorage(selectedPlan.opensearchStorageMin * 3);
                    setTotalPrice(selectedPlan.opensearchPriceMin * 3);
                  }}
                >
                  <span>3 nodes</span>
                </div>

                {/* Conditionally render 6, 9, and 15 node buttons if [1,3,6,9,15] */}
                {selectedPlan.opensearchNodes === "[1,3,6,9,15]" && (
                  <>
                    <div
                      className={`radio-button ${nodes === 6 ? 'radio-button-active' : ''}`}
                      onClick={() => { 
                        setNodes(6); 
                        setScalableStorage(selectedPlan.opensearchStorageMin * 6);
                        setTotalPrice(selectedPlan.opensearchPriceMin * 6);
                      }}
                    >
                      <span>6 nodes</span>
                    </div>
                    <div
                      className={`radio-button ${nodes === 9 ? 'radio-button-active' : ''}`}
                      onClick={() => { 
                        setNodes(9); 
                        setScalableStorage(selectedPlan.opensearchStorageMin * 9);
                        setTotalPrice(selectedPlan.opensearchPriceMin * 9);
                      }}
                    >
                      <span>9 nodes</span>
                    </div>
                    <div
                      className={`radio-button ${nodes === 15 ? 'radio-button-active' : ''}`}
                      onClick={() => { 
                        setNodes(15); 
                        setScalableStorage(selectedPlan.opensearchStorageMin * 15);
                        setTotalPrice(selectedPlan.opensearchPriceMin * 15);
                      }}
                    >
                      <span>15 nodes</span>
                    </div>
                  </>
                )}
              </div>
            </>
          )}

					<h5>SELECT SCALABLE STORAGE SIZE</h5>
          <div className="flex flex-start flex-center gap-2">
						<div className="stepper-container">
							<div className="stepper-input-wrapper">
									<div className="stepper-input">
											<input 
											type="number" 
											className="stepper-input-field" 
											name="storage-count" 
											placeholder="" 
											min={selectedPlan ? selectedPlan.opensearchStorageMin * nodes : undefined}
											max={selectedPlan ? selectedPlan.opensearchStorageMax * nodes : undefined} 
											aria-invalid="false" 
											autoComplete="off" 
											spellCheck="false" 
											value={scalableStorage} // Bind the input to state
											onChange={(e) => {
													const value = e.target.value;

													// Allow the field to be empty
													if (value === '') {
													setScalableStorage('');
													return;
													}

													const numericValue = parseInt(value, 10);

													// If the input is not a valid number, do nothing
													if (isNaN(numericValue)) {
													return;
													}

													setScalableStorage(numericValue);
											}}
                      onBlur={(e) => {
                        if (!selectedPlan) return; // Ensure a plan is selected before enforcing limits
                    
                        let value = parseInt(e.target.value, 10);
                    
                        // If the input is empty, reset to the minimum value without rounding up
                        if (isNaN(value)) {
                            setScalableStorage(selectedPlan.opensearchStorageMin);
                            calculateTotalPrice(selectedPlan.opensearchStorageMin, selectedPlan, nodes); // Calculate price on blur
                            return;
                        }
                    
                        const minStorage = selectedPlan.opensearchStorageMin * nodes;
                        const maxStorage = selectedPlan.opensearchStorageMax * nodes;
                        const roundTo = 10 * nodes; // Multiply rounding factor by number of nodes
                    
                        // Enforce minimum and maximum constraints without rounding up
                        if (value <= minStorage) {
                            setScalableStorage(minStorage);
                            calculateTotalPrice(minStorage, selectedPlan, nodes); // Calculate price on blur
                        } else if (value >= maxStorage) {
                            setScalableStorage(maxStorage);
                            calculateTotalPrice(maxStorage, selectedPlan, nodes); // Calculate price on blur
                        } else {
                            // Calculate the offset from the minimum value
                            const offset = minStorage % roundTo;
                    
                            // Adjust rounding to consider the offset from the minimum value
                            value = Math.ceil((value - offset) / roundTo) * roundTo + offset;
                    
                            setScalableStorage(value);
                            calculateTotalPrice(value, selectedPlan, nodes); // Calculate price on blur
                        }
                    }}                    
										/>
									</div>
							</div>
							<div className="stepper-button-wrapper-up">
									<button 
											className="stepper-button-increment" 
											onClick={handleStorageIncrement}
											disabled={scalableStorage == selectedPlan.opensearchStorageMax * nodes} // Disable if current storage is at the maximum limit
									>
											<svg className="stepper-icon-up" aria-hidden="true">
													<use href="#_sprite-arrow-up"></use>
											</svg>
									</button>
							</div>
							<div className="stepper-button-wrapper-down">
									<button 
											className="stepper-button-decrement" 
											onClick={handleStorageDecrement}
											disabled={scalableStorage == selectedPlan.opensearchStorageMin * nodes} // Disable if current storage is at the minimum limit
									>
											<svg className="stepper-icon-down" aria-hidden="true">
													<use href="#_sprite-arrow-down"></use>
											</svg>
									</button>
							</div>
						</div>
						<div className='font-size-dot8'>
							<p className="font-weight-600">Storage Range</p>
							<p>{selectedPlan.opensearchStorageMin * nodes} GiB - {selectedPlan.opensearchStorageMax * nodes} GiB</p>
						</div>
					</div>

          <h5>SELECT DATABASE QUANTITY</h5>
          <input
            ref={quantityInputRef}
            type="number"
            className="input"
            value={quantity}
            onChange={handleQuantityChange}
            onBlur={handleQuantityBlur}
            min="1"
            max="1000"
          />
				</div>
      )}
			<div id="modal-footer" className="modal-footer flex flex-between flex-center">
				<div id="action-button" className="action-button">
						<button
						className="button-blue"
						onClick={handleAdd}
						disabled={!selectedPlan} // Disable the button if no plan is selected
						>
						Add OpenSearch
						</button>
				</div>
				<div id="price" className="price">
						<span className="dollar dollar-large">$</span>
						<span className="cost cost-large">{selectedPlan ? formatPrice(Number(totalPrice.toString().replace(/,/g, '')) * quantity) : '0'}</span>
						<span className="period period-large">/mo</span>
				</div>
			</div>
		</div>
  );
}

export default TabOpenSearch;
import React, { useState } from 'react';
import './DatabasesModal.css';
import TabMySQL from './databasetabs/TabMySQL';
import TabPostgreSQL from './databasetabs/TabPostgreSQL';
import TabRedis from './databasetabs/TabRedis';
import TabMongoDB from './databasetabs/TabMongoDB';
import TabKafka from './databasetabs/TabKafka';
import TabOpenSearch from './databasetabs/TabOpenSearch';

function DatabasesModal({ closeModal, addQuoteItem, formatPrice, plansDatabases }) {
  const [activeTab, setActiveTab] = useState('MySQL');
  const [name, setName] = useState(''); // State to track the input field value

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div id="modal" className="modal">
      <div id="modal-content" className="modal-content databases-modal">
        <span id="close" className="close" onClick={closeModal}>&times;</span>
        <div id="modal-header" className="modal-header flex flex-between flex-center">
          <div>
            <h1>Add Databases</h1>
          </div>
          <div id="modal-item-name" className="modal-item-name">
            <input
              type="text"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)} // Update the state when the input changes
              placeholder="Enter item name or description"
              maxLength="50"
            />
          </div>
        </div>
        <div id="modal-tabs" className="modal-tabs">
          <button
            className={`tab-button ${activeTab === 'MySQL' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('MySQL')}
          >
            MySQL
          </button>
          <button
            className={`tab-button ${activeTab === 'PostgreSQL' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('PostgreSQL')}
          >
            PostgreSQL
          </button>
          <button
            className={`tab-button ${activeTab === 'Redis' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Redis')}
          >
            Redis
          </button>
          <button
            className={`tab-button ${activeTab === 'MongoDB' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('MongoDB')}
          >
            MongoDB
          </button>
          <button
            className={`tab-button ${activeTab === 'Kafka' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('Kafka')}
          >
            Kafka
          </button>
          <button
            className={`tab-button ${activeTab === 'OpenSearch' ? 'tab-button-active' : ''}`}
            onClick={() => handleTabClick('OpenSearch')}
          >
            OpenSearch
          </button>
        </div>
        {/* Render the active tab component and pass the name and closeModal props */}
        {activeTab === 'MySQL' && (
          <TabMySQL
            name={name}
            addQuoteItem={addQuoteItem}
            formatPrice={formatPrice}
            plansDatabases={plansDatabases}
            closeModal={closeModal}
          />
        )}
        {activeTab === 'PostgreSQL' && (
          <TabPostgreSQL
            name={name}
            addQuoteItem={addQuoteItem}
            formatPrice={formatPrice}
            plansDatabases={plansDatabases}
            closeModal={closeModal}
          />
        )}
        {activeTab === 'Redis' && (
          <TabRedis
            name={name}
            addQuoteItem={addQuoteItem}
            formatPrice={formatPrice}
            plansDatabases={plansDatabases}
            closeModal={closeModal}
          />
        )}
        {activeTab === 'MongoDB' && (
          <TabMongoDB
            name={name}
            addQuoteItem={addQuoteItem}
            formatPrice={formatPrice}
            plansDatabases={plansDatabases}
            closeModal={closeModal}
          />
        )}
        {activeTab === 'Kafka' && (
          <TabKafka
            name={name}
            addQuoteItem={addQuoteItem}
            formatPrice={formatPrice}
            plansDatabases={plansDatabases}
            closeModal={closeModal}
          />
        )}
        {activeTab === 'OpenSearch' && (
          <TabOpenSearch
            name={name}
            addQuoteItem={addQuoteItem}
            formatPrice={formatPrice}
            plansDatabases={plansDatabases}
            closeModal={closeModal}
          />
        )}
      </div>
    </div>
  );
}

export default DatabasesModal;